.DiscountCta {
  display: flex;
  align-items: flex-start;
  position: relative;
  gap: clamp(30px, calc(32vw / 7.68), 32px);

  .image {
    position: relative;
    width: 100%;
    img {
      max-width: clamp(320px, calc(320vw / 7.68), 484px);
      width: 100%;
      align-items: stretch;
      border-radius: 6px;
      flex-shrink: 0;
    }
    .discount {
      position: absolute;
      top: 12px;
      left: 12px;
      width: 128px;
      aspect-ratio: 1/1;
      border-radius: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--success-200, #d0dad2);
      font-weight: 300;
      line-height: 1.5;
      flex-direction: column;
      color: var(--success-900, #18251b);

      font-size: clamp(calc(14rem / 16), calc(18vw / 7.68), calc(18rem / 16));

      strong {
        font-size: clamp(calc(16rem / 16), calc(24vw / 7.68), calc(24rem / 16));
        font-weight: 300;
      }
    }
  }

  .header {
    font-weight: 300;

    .heading {
      font-size: clamp(calc(24rem / 16), calc(36vw / 7.68), calc(36rem / 16));
      line-height: 1.2;
      margin-bottom: clamp(12px, calc(28vw / 7.68), 28px);
    }

    .paragraph {
      margin-bottom: clamp(12px, calc(28vw / 7.68), 28px);
      line-height: 1.5;

      > p:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    .additionalParagraph {
      margin-bottom: clamp(20px, calc(36vw / 7.68), 36px);
      font-size: calc(14rem / 16);
      line-height: 1.5;
    }

    .additionalText {
      margin-top: 8px;
      font-size: calc(14rem / 16);
      line-height: 1.5;
    }
  }

  & > svg {
    position: absolute;
    bottom: -5%;
    right: 0;
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    max-width: 608px;
    margin: 0 auto;
    .image {
      img {
        max-width: 484px;
      }
    }

    .header {
      max-width: 584px;
    }

    & > svg {
      display: none;
    }
  }
}
