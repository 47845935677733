.Faq {
  header {
    max-width: calc(584rem/16);
    margin: 0 auto clamp(28px, calc(48vw/7.68), 80px);
    text-align: center;
    .paragraph {
      margin-top: clamp(16px, calc(28vw/7.68), 36px);
    }
  }
  .list {
    counter-reset: counter;
    details {
      counter-increment: counter;
      &:not(:last-child){
        border-bottom: 1px solid var(--primary-300);
      }
      .indicator {
        margin-top: clamp(13px, calc(19vw/7.68), 19px);
        transition: transform .5s var(--easing);
      }
      summary {
        &:hover:not(:active) {
          &::before {
            transform: scale(1.1);
          }
          span {
            transform: translateX(8px);
          }
        }
        padding: clamp(28px, calc(36vw/7.68), 36px) 0 clamp(16px, calc(24vw/7.68), 28px);
        &::before {
          content: counter(counter);
          width: clamp(40px, calc(60vw/7.68), 60px);
          height: clamp(40px, calc(60vw/7.68), 60px);
          text-align: center;
          font-size: clamp(32px, calc(36vw/7.68), 48px);
          font-family: var(--font-city-streetwear);
          border-radius: 50%;
          background-color: var(--primary-200);
          line-height: calc(clamp(40px, calc(60vw/7.68), 60px) * 1.3);
          transition: transform .5s var(--easing);
        }
        display: grid;
        grid-template-columns: auto 1fr auto;
        gap: clamp(12px, calc(16vw/7.68), 36px);
        font-size: clamp(calc(18rem/16), calc(24vw/7.68), calc(28rem/16));
        span {
          margin-top: .5em;
          max-width: 800px;
          transition: transform .5s var(--easing);
        }
      }
      .answer {
        overflow: hidden;
        margin-left: calc(clamp(40px, calc(66vw/7.68), 60px) + clamp(12px, calc(16vw/7.68), 36px));
        max-width: 800px;
        p:not(:last-child){
          margin-bottom: 8px;
        }
      }
      &[data-opened="true"]{
        .indicator {
          transform: rotate(45deg);
        }
        summary span {
          transform: translateX(0) !important;
        }
      }
    }
  }
}