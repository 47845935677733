.HeroBackgroundImg {
  &:first-of-type {
    margin-top: calc(clamp(36px, calc(52vw / 7.68), 64px) * -1);
    margin-bottom: clamp(64px, calc(80vw / 7.68), 96px);
  }
  position: relative;
  img {
    position: absolute;
    max-width: calc(100% + var(--pageMargin) * 2);
    min-height: 380px;
    max-height: 600px;
    height: 100%;
    object-position: left 30%;
    object-fit: cover;
    margin: 0 calc(var(--pageMargin) * -1);
    @media (min-width: 1280px) {
      max-width: 100vw;
      width: 100vw;
      margin: 0 calc((100vw - 1200px) / 2 * -1);
    }
  }
  header {
    position: relative;
    z-index: 2;
    margin: calc(clamp(48px, calc(64vw / 7.68), 96px) * 2) 0 clamp(16px, calc(64vw / 7.68), 64px) auto;
    padding: clamp(20px, calc(32vw / 7.68), 42px) clamp(16px, calc(28vw / 7.68), 36px);
    border-radius: 6px;
    border: 1px solid var(--primary-400);
    background: var(--primary-100);
    width: 60%;
    max-width: calc(670rem / 16);
    @media (max-width: 899px) {
      width: 100%;
      margin: calc(clamp(48px, calc(64vw / 7.68), 96px) * 4) auto clamp(16px, calc(64vw / 7.68), 64px);
    }
    h1 {
      margin-bottom: clamp(12px, calc(16vw / 7.68), 28px);
    }
    .paragraph {
      p:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    .cta {
      margin-top: clamp(16px, calc(28vw / 7.68), 36px);
    }
    .ctaAnnotation {
      font-size: calc(14rem / 16);
      margin-top: 8px;
    }
  }
  &[data-reversed='true'] {
    img {
      object-position: 75% 30%;
    }
    header {
      margin-left: 0;
      margin-right: auto;
    }
  }
}
