.CompaniesShowcase {
  display: grid;
  justify-items: center;
  gap: clamp(32px, calc(40vw/7.68), 48px);

  header {
    max-width: calc(584rem / 16);
    display: grid;
    gap: clamp(12px, calc(24vw / 7.68), 36px);
    justify-items: center;
  }

  .list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;

    @media (max-width: 799px) {
      grid-template-columns: 1fr;
    }

    .item {
      display: grid;
      gap: clamp(20px, calc(24vw/7.68), 36px);
      padding: clamp(16px, calc(28vw/7.68), 32px) clamp(16px, calc(32vw/7.68), 36px) clamp(16px, calc(38vw/7.68), 36px);
      background: var(--primary-200);
      align-items: flex-start;

      .imageWrapper {
        overflow: hidden;

        >img {
          height: clamp(64px, calc(112vw/7.68), 112px);
          width: auto;
          transition: transform 0.4s var(--easing);
        }
      }

      .text {
        display: grid;
        gap: clamp(12px, calc(16vw/7.68), 20px);
      }

      &.link:hover {
        .imageWrapper {
          >img {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}