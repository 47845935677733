.Divider {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: clamp(24px, calc(48vw / 7.68), 56px);
  &::before,
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: var(--primary-400);
    transform: scaleX(0);
    transition: transform .8s var(--easing);
  }
  &::before {
    transform-origin: left;
  }
  &::after {
    transform-origin: right;
  }
  &:has(svg[data-visible='true']) {
    &::before,
    &::after {
      transform: scaleX(1);
    }
    .icon {
      &::before {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
  .icon {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0);
      width: 70px;
      height: 70px;
      background-color: var(--primary-200);
      border-radius: 50%;
      z-index: -1;
      transition: transform 0.8s 0.8s var(--easing);
    }
  }
}
