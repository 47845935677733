.TableOfContent {
  display: grid;
  gap: clamp(15px, calc(22vw/7.68), 28px);
  padding: 20px clamp(24px, calc(36vw/7.68), 28px) 28px clamp(24px, calc(36vw/7.68), 28px);
  background: var(--primary-200);

  @media(max-width: 999px) {
    padding: 20px 24px 28px 24px;
    height: 400px;
    gap: 15px;
  }

  header {
    align-self: center;
    justify-self: center;
  }

  li {
    list-style-type: none;
    display: grid;
    gap: 16px;
  }

  >div {
    display: grid;
    gap: 24px;
    grid-template-columns: auto 1fr;

    @media (max-width: 999px) {
      grid-template-columns: 1fr;
    }

    .progressBar {
      width: 4px;
      background-color: var(--primary-300);
      border-radius: 1px;
      position: relative;
      margin: 10px 0px;

      @media (max-width: 999px) {
        display: none;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: var(--progressPercentage);
        background: var(--primary-600);
        border-radius: 1px;
        z-index: 1;
      }
    }

    .unorderedList {
      overflow-y: auto;
      max-height: calc(100vh - 300px);
      padding: 10px 10px 10px 0px;
      font-size: calc(18rem / 16);
      display: grid;
      gap: 16px;
      color: var(--primary-600);

      @media (max-width: 999px) {
        max-height: 300px;
        justify-items: flex-start;
      }

      .subList {
        font-size: calc(14rem / 16);
        gap: 16px;
        display: grid;
        color: var(--primary-600);
      }
    }
  }

  .link {
    width: fit-content;
    text-align: start;
    transition: color 0.3s ease;

    &:hover {
      color: var(--primary-900);
    }
  }

  @media (max-width: 999px) {
    &::before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 5px;
      background: var(--primary-300);
      border-radius: 0px 0px 4px 4px;
      z-index: 1;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: var(--progressPercentage);
      height: 5px;
      background: var(--primary-500);
      border-radius: 0px 0px 4px 4px;
      z-index: 1;
    }
  }
}